import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { AppBar, Button, CircularProgress, createStyles, Icon, IconButton, makeStyles, Snackbar, Theme, Toolbar, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import MenuIcon from '@material-ui/icons/Menu';

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";

const useMyStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    connectWrapper:{
      display:'flex',
      alignContent:'center',
      justifyContent:'center',
      textAlign:'center',   
      alignItems:'center',
      gap:5,   
    },
    artwork:{
      width:'40%'
      // height:'30%',
    },
    mintContainerInner:{
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
      gap:10
    },
    noWalletConnected:{
      display:'flex',
      flexDirection:'column',
      gap:10,
      justifyContent:'center',
      alignItems:'center',
      // paddingTop:'13%',
    }
  }),
);

const MainContainer = styled.main`
// background-color:red;
// display:flex;
// flex-direction:column;
// justify-content: flex-center;
// align-items:center;
// align-content:center;
// height:100%;
// min-height:500px;
`;
const ConnectButton = styled(WalletDialogButton)`
background-color:white;
color:black;
border:1px;
border-color:white;
&:hover{
  background-color:gray;
  color:white;
}
`;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div`
display:flex;
justify-content:center;
padding:20px
`; // add your styles here

const MintButton = styled(Button)`
  background-color:green;
  font-size:18px;
  font-weight:bold;
  color:white;
  width:150px;
  justify-content:center;
  &:hover{
    background-color:gray;
    color:white;
  }
`; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {

  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT
  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);
  
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();
  const styles = useMyStyles();

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
          setItemsRedeemed(itemsRedeemed+1); // triggers to get candymachine state
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(() => {
    (async () => {
      if (!wallet) return;

      const { candyMachine, goLiveDate, itemsRemaining ,itemsRedeemed,itemsAvailable} =
        await getCandyMachineState(
          wallet as anchor.Wallet,
          props.candyMachineId,
          props.connection
        );
      setItemsRedeemed(itemsRedeemed);
      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);

      /**
       * Stopping mint button on 500 
       */
      // setIsSoldOut(itemsRedeemed > 499);
      
      /**
        * ENDL
      */
      setIsSoldOut(itemsRemaining === 0);
      // setIsSoldOut(false);
      setStartDate(goLiveDate);
      
  
      setCandyMachine(candyMachine);
    })();
  }, [wallet, props.candyMachineId, props.connection,itemsRedeemed]);
  return (
    <MainContainer>
      <AppBar elevation={0} position="static" style={{background:"transparent"}}>
  <Toolbar>
    {/* <IconButton edge="start"  color="inherit" aria-label="menu">
      <MenuIcon />
    </IconButton> */}
    <Typography variant="h5" className={styles.title} >
      Solnoodles
    </Typography>
    {/* <Button color="inherit">Login</Button> */}
    {/* <Icon>star</Icon> */}

    { wallet? 
      <div className={styles.connectWrapper}>
        <Icon>account_balance_wallet</Icon>
       { shortenAddress(wallet.publicKey.toBase58() || "")}
       </div> 
      :
      <ConnectButton >Connect Wallet</ConnectButton>
    }

    {/* { !wallet && <ConnectButton >Connect Wallet</ConnectButton>} */}

  </Toolbar>
</AppBar>
      {/* {wallet && (
        <p>Address: {shortenAddress(wallet.publicKey.toBase58() || "")}</p>
      )}

      {wallet && (
        <p>Balance: {(balance || 0).toLocaleString()} SOL</p>
      )} */}

      <MintContainer>
        {!wallet ? (
          <div className={styles.noWalletConnected}>
          
            {/* <Icon style={{fontSize:'70px'}}>account_balance_wallet</Icon> */}
            <img src="solnoodles.jpg" style={{borderRadius:"50%"}} alt="" />
            <p>Please connect your wallet for minting Solnoodles.</p>
          </div>
        ) : (
          <div className={styles.mintContainerInner}>
            {!isSoldOut && 
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',textAlign:'center'}}>
              <img src="solnoodles.jpg" style={{borderRadius:"50%"}} alt="" />

              {/* <img src="Good_Luck.png" style={{width:"40%"}} alt="" /> */}
              <p style={{fontSize:"18px"}}>
                Minting Price: <strong style={{color:'lightgreen'}}>0.45 SOL</strong>  
                
              </p>
              {/* <p style={{fontSize:"18px"}}>Pre-Sale SOLD OUT!</p> */}
              {/* <p style={{fontSize:"14px",marginTop:"-8px"}}>
              (Limited Pre-Sale Price until 18th oct 21 04:00 GMT)
              </p> */}
            </div>
            
            // <img className={styles.artwork} src={ASSETS_CDN_URL+ itemsRedeemed + ".png"} alt="" />
            }
            {
              isSoldOut &&
              <img src="solnoodles.jpg" style={{borderRadius:"50%"}} alt="" />

            }
            <MintButton
              disabled={isSoldOut || isMinting || !isActive}
              onClick={onMint}
              variant="contained"
            >
              {
              isSoldOut ? (
                "SOLD OUT"
              ) : isActive ? (
                isMinting ? (
                  <CircularProgress />
                ) : (
                  "MINT"
                )
              ) : (
                <Countdown
                  date={startDate}
                  onMount={({ completed }) =>  completed && setIsActive(true)}
                  onComplete={() => setIsActive(true)}
                  renderer={renderCounter}
                />
              )}
            </MintButton>
          </div>

        )}
      </MintContainer>

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </MainContainer>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
